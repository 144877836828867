import { getConnectionStatusLive } from "./connectionStatusLiveSlice";
import { getConnectionStatus } from "./connectionStatusCacheSlice";
import { getServiceGroup } from "./serviceGroupSlice";
import { getVehicleClaims } from "./vehicleClaimsSlice";
import { getVehicleByUuid } from "./vehicleUuIDSlice";
import { getVehicleUsers } from "./vehicleUsersSlice";
import { getVehicleJobs } from "./activationJobsSlice";
import { getOdpUserList } from "./odpUserList/odpUserListSlice";
import { getAllVehicleServiceClaims } from "./allVehicleServiceClaimsSlice";
import { getUserClaimsPrimaryUser } from "./primaryUserClaimsSlice";
import { getVehicleCurrentState } from "./vehicleCurrentStateSlice";

// These are the functions that need to run AFTER the VDS check
// The info coming back will populate UI sections and graphs

export const triggerBackendFunctions = (
  dispatch,
  environment,
  region,
  vin,
  timeouts
) => {
  dispatch(getUserClaimsPrimaryUser(environment, region, vin.toUpperCase()));
  dispatch(getServiceGroup(environment, region, vin.toUpperCase()));
  dispatch(getVehicleByUuid(environment, region, vin.toUpperCase()));
  dispatch(getVehicleUsers(environment, region, vin.toUpperCase()));
  dispatch(getVehicleJobs(environment, region, vin.toUpperCase()));
  dispatch(getVehicleClaims(environment, region, vin.toUpperCase()));
  dispatch(getAllVehicleServiceClaims(environment, region, vin.toUpperCase()));
  dispatch(getVehicleCurrentState(environment, region, vin.toUpperCase()));
  dispatch(getOdpUserList(environment, region, vin.toUpperCase()));

  // Clear any timeouts:
  for (let i = 1; i < 4; i++) {
    clearTimeout(timeouts[i]);
  }

  // The getConnectionStatus at 15 seconds intevall, cached info:
  for (let i = 1; i < 4; i++) {
    timeouts[i] = setTimeout(() => {
      dispatch(getConnectionStatus(environment, region, vin.toUpperCase()));
      dispatch(getConnectionStatusLive(environment, region, vin.toUpperCase()));
    }, i * 15000);

    return () => {
      for (let i = 1; i < 4; i++) {
        clearTimeout(timeouts[i]);
      }
    };
  }
};
