import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import classes from "./backendDataInfoPage.module.css";
import HorizontalDivider from "../../components/Horizontal Divider/HorizontalDivider";
import { useDispatch, useSelector } from "react-redux";
import search from "../../ui/Assets/Icons/search.png";
import {
  checkEnvironmentParams,
  checkRegionParams,
  checkUuidParams,
  checkVinParams,
} from "./paramsCheckers";
import {
  regionHandler,
  environmentHandler,
  // searchHandler,
  vinHandler,
} from "./selectionHandlers";
import { cleanSections } from "./sectionsCleaner";
import { triggerVDS } from "./triggerVDS";
import {
  selectVIN,
  selectEnvironment,
  selectRegion,
  setVIN,
  setRegion,
  setEvironment,
  selectPresetEnvironment,
  selectPresetRegion,
  selectEmptyFields,
  setEmptyFields,
  setUuid,
} from "./backendInfoSlice";
import {
  selectServiceGroup,
  selectisLoadingDataServiceGroup,
  setRelatedSOPs,
  setServiceGroup,
} from "./serviceGroupSlice";
import {
  selectIsLoadingDataVehicleUuID,
  selectVehicleUuID,
  setVehicleUuID,
} from "./vehicleUuIDSlice";
import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import WideTable from "./wideTable";
import SopTable from "./sopTable";
import JsonProtocols from "./jsonProtocols";
import VehicleStatusTableLive from "./vehicleStatusTableLive";
import {
  selectIsLoadingDataVinCheck,
  setUrl,
  setVinAvailableInOtherEnvOrReg,
  setVinStagesAndEnvironmentsCheck,
} from "./vdsCheckSlice";
import OdpUserList from "./odpUserList/OdpUserList";
////////
import {
  setAccessForbidden,
  clearPingData,
  clearPingTimeStamp,
  getConnectionStatus,
  setConnectionStatus,
  setPingTimestamp,
} from "./connectionStatusCacheSlice";

import {
  setAccessForbiddenToState,
  setVehicleCurrentState,
} from "./vehicleCurrentStateSlice";
import {
  clearPingDataLive,
  clearPingTimeStampLive,
  getConnectionStatusLive,
  setConnectionStatusLive,
} from "./connectionStatusLiveSlice";

import { triggerBackendFunctions } from "./triggerBackendFunctions";
import { setVehicleUsers } from "./vehicleUsersSlice";
import { setOdpUserList } from "./odpUserList/odpUserListSlice";
import { setVehicleJobs } from "./activationJobsSlice";
import { setVehicleClaims } from "./vehicleClaimsSlice";
import { setAllVehicleServiceClaims } from "./allVehicleServiceClaimsSlice";
import { setPrimaryUserClaims } from "./primaryUserClaimsSlice";
//////////////

const timeouts = [];
function BackendDataInfoPage() {
  const { searchParams } = useParams();

  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const emptyFields = useSelector(selectEmptyFields);

  const isLoadingDataVINCheck = useSelector(selectIsLoadingDataVinCheck);
  const isLoadingDataServiceGroup = useSelector(
    selectisLoadingDataServiceGroup
  );
  const isLoadingDataVehicleUuid = useSelector(selectIsLoadingDataVehicleUuID);

  const vehicleUuID = useSelector(selectVehicleUuID);
  const serviceGroup = useSelector(selectServiceGroup);

  const vin = useSelector(selectVIN);

  const environment = useSelector(selectEnvironment);
  const preselectEnvironment = useSelector(selectPresetEnvironment);

  const region = useSelector(selectRegion);
  const preselectRegion = useSelector(selectPresetRegion);

  function checkURLParams(environment, region, vin, uuid) {
    return (
      (checkEnvironmentParams(environment) &&
        checkRegionParams(region) &&
        checkVinParams(vin)) ||
      checkUuidParams(uuid)
    );
  }

  const searchHandler = (dispatch) => {
    console.log(vin);
    const vinRegex = /^[A-Za-z0-9]{17}$/;

    if (vin !== "") {
      if (vinRegex.test(vin)) {
        // we need to clear existing data from table so it is not confusing
        dispatch(setVinAvailableInOtherEnvOrReg(false));
        dispatch(setVehicleUuID({}));
        dispatch(setVehicleUsers({}));
        dispatch(setServiceGroup({}));
        dispatch(setOdpUserList({}));
        dispatch(setVehicleJobs([]));
        dispatch(setRelatedSOPs([]));
        dispatch(setVehicleClaims({}));
        dispatch(setConnectionStatus({}));
        dispatch(setPingTimestamp([]));
        dispatch(setAllVehicleServiceClaims({}));
        dispatch(setPrimaryUserClaims({}));
        dispatch(setVehicleCurrentState({}));

        // Clar info for live data
        dispatch(setConnectionStatusLive({}));
        dispatch(clearPingDataLive());
        dispatch(clearPingTimeStampLive());

        dispatch(setEmptyFields(true));
        dispatch(setAccessForbidden(false));
        dispatch(setAccessForbiddenToState(false));
        // Clear timeouts so no calls are made
        for (let i = 1; i < 4; i++) {
          clearTimeout(timeouts[i]);
        }

        // Trigger backend functions
        triggerBackendFunctions(dispatch, environment, region, vin, timeouts);
        // Data is incoming, so loading spinners should be shown
        dispatch(setEmptyFields(false));

        // No access forbidden messages
        dispatch(setAccessForbidden(false));
        dispatch(setAccessForbiddenToState(false));

        // Clear any timeouts:
        for (let i = 1; i < 4; i++) {
          clearTimeout(timeouts[i]);
        }

        // The getConnectionStatus at 15 seconds intevall, cached info:
        for (let i = 1; i < 4; i++) {
          timeouts[i] = setTimeout(() => {
            dispatch(
              getConnectionStatusLive(environment, region, vin.toUpperCase())
            );
            console.log("Timeouts in the iteraion" + timeouts[i]);
          }, i * 15000);
        }
        // Set the new URL with the user selection
        history.push(`/vehicleBackendDataAnalysis/${newSearchParams}`);
      } else {
        console.log("Invalid VIN!");
        setError(true);
      }
    } else {
      console.log("VIN is empty!");

      setError(true);
    }
  };

  const automaticSearch = (dispatch, environment, region, vin, timeouts) => {
    cleanSections(dispatch);
    setError(false);

    // Trigger backend functions
    triggerBackendFunctions(dispatch, environment, region, vin, timeouts);
    // Data is incoming, so loading spinners should be shown
    dispatch(setEmptyFields(false));

    // No access forbidden messages
    dispatch(setAccessForbidden(false));
    dispatch(setAccessForbiddenToState(false));

    // Clear any timeouts:
    for (let i = 1; i < 4; i++) {
      clearTimeout(timeouts[i]);
    }

    // The getConnectionStatus at 15 seconds intevall, cached info:
    for (let i = 1; i < 4; i++) {
      timeouts[i] = setTimeout(() => {
        // dispatch(getConnectionStatus(environment, region, vin.toUpperCase()));
        dispatch(
          getConnectionStatusLive(environment, region, vin.toUpperCase())
        );
        console.log("Timeouts in the iteraion" + timeouts[i]);
      }, i * 15000);
    }
  };

  ////////////////////
  /////////NO VDS Check///////////
  ////////////////////
  useEffect(() => {
    // STEP 1:
    // Check if searchParams exists
    const paramsArray = searchParams ? searchParams.split("&") : [];

    const paramsObject = paramsArray.reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[key] = value;
      return acc;
    }, {});

    const {
      vin: paramsVIN,
      reg: paramsRegion,
      env: paramsEnvironment,
    } = paramsObject;

    console.log("VIN from params    " + paramsVIN);
    console.log("ENV from params    " + paramsEnvironment);
    console.log("REG from params    " + paramsRegion);

    // Use the initial presets or set to preselected values if searchParams doesn't exist

    // if (paramsEnvironment && paramsRegion && paramsVIN) {
    //   dispatch(setVIN(paramsVIN !== undefined ? paramsVIN : ""));
    //   dispatch(setRegion(paramsRegion !== undefined ? paramsRegion : ""));
    //   dispatch(
    //     setEvironment(paramsEnvironment !== undefined ? paramsEnvironment : "")
    //   );
    // }

    // STEP 2:
    // Check if params values, if they exist, are in a valid form
    if (checkURLParams(paramsEnvironment, paramsRegion, paramsVIN)) {
      // If in correct format, set the values to params values
      dispatch(setVIN(paramsVIN !== undefined ? paramsVIN : ""));
      dispatch(setRegion(paramsRegion !== undefined ? paramsRegion : ""));
      dispatch(
        setEvironment(paramsEnvironment !== undefined ? paramsEnvironment : "")
      );
    }

    console.log("VIN set     " + paramsVIN);
    console.log("ENV set     " + paramsEnvironment);
    console.log("REG set     " + paramsRegion);

    if (emptyFields) {
      dispatch(clearPingData());
      dispatch(clearPingTimeStamp());
      // dispatch(setEmptyFields(true));
    }

    automaticSearch(
      dispatch,
      paramsEnvironment,
      paramsRegion,
      paramsVIN,
      timeouts
    );
    return () => {
      for (let i = 1; i < 4; i++) {
        clearTimeout(timeouts[i]);
      }
    };
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, preselectRegion, preselectEnvironment, searchParams]);

  const history = useHistory();
  const newSearchParams = `vin=${vin}&reg=${region}&env=${environment}`;

  return (
    <div className={classes.flexContainerHeader}>
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRow}> </div>
      <div
        className={classes.flexContainerRowTitle}
        style={{ userSelect: "text", cursor: "auto" }}
      >
        Vehicle Backend Data Analysis
      </div>{" "}
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRow}> </div>
      <HorizontalDivider />
      <div className={classes.flexContainerRow}> </div>
      {/* ------------------ Region & Environment selection -------------------- */}
      <div className={classes.regionSelectionRow}>
        <div className={classes.regionSection}>
          <FormControl>
            <FormLabel id="reg-radio-buttons-group-label">Region</FormLabel>
            <RadioGroup
              aria-labelledby="reg-radio-buttons-group-label"
              value={
                region !== undefined && region !== "" ? region : preselectRegion
              }
              onChange={(event) => {
                regionHandler(
                  dispatch,
                  timeouts,
                  event.target.value,
                  history,
                  vin,
                  environment
                );
              }}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="ece"
                control={<Radio size="small" />}
                label="ECE"
              />
              <FormControlLabel
                value="nar"
                control={<Radio size="small" />}
                // disabled
                label="NAR"
              />
              <FormControlLabel
                value="cn"
                control={<Radio size="small" />}
                // disabled
                label="CN"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.environmentSection}>
          <FormControl>
            <FormLabel id="env-radio-buttons-group-label">
              Environment
            </FormLabel>
            <RadioGroup
              aria-labelledby="env-radio-buttons-group-label"
              value={
                environment !== undefined && environment !== ""
                  ? environment
                  : preselectEnvironment
              }
              onChange={(event) =>
                environmentHandler(
                  dispatch,
                  timeouts,
                  event.target.value,
                  history,
                  vin,
                  region
                )
              }
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="demo"
                disabled
                control={<Radio size="small" />}
                label="Demo"
              />
              <FormControlLabel
                value="approval"
                control={<Radio size="small" />}
                label="Approval"
              />
              <FormControlLabel
                value="live"
                control={<Radio size="small" />}
                label="Live"
                disabled={region === "cn" ? true : false}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {/* ---------------------------------------------------------------------- */}
      <div className={classes.flexContainerRow}> </div>
      <div
        className={classes.flexContainerRowSubtitle}
        style={{ userSelect: "text", cursor: "auto" }}
      >
        {" "}
        Selected VIN:{" "}
      </div>
      <div className={classes.flexContainerRowSearch}>
        {/*  */}
        <input
          id="searchDetails"
          autoComplete="off"
          required
          data-cy="searchInput"
          value={vin || ""}
          onChange={(event) => {
            dispatch(setVinAvailableInOtherEnvOrReg(false));
            dispatch(setVehicleUuID({}));
            dispatch(setVehicleUsers({}));
            dispatch(setServiceGroup({}));
            dispatch(setOdpUserList({}));
            dispatch(setVehicleJobs([]));
            dispatch(setRelatedSOPs([]));
            dispatch(setVehicleClaims({}));
            dispatch(setConnectionStatus({}));
            dispatch(setPingTimestamp([]));
            dispatch(setAllVehicleServiceClaims({}));
            dispatch(setPrimaryUserClaims({}));
            dispatch(setVehicleCurrentState({}));

            // Clar info for live data
            dispatch(setConnectionStatusLive({}));
            dispatch(clearPingDataLive());
            dispatch(clearPingTimeStampLive());

            dispatch(setEmptyFields(true));
            dispatch(setAccessForbidden(false));
            dispatch(setAccessForbiddenToState(false));
            // Clear timeouts so no calls are made
            for (let i = 1; i < 4; i++) {
              clearTimeout(timeouts[i]);
            }
            // Clear the VDS check
            dispatch(setVinStagesAndEnvironmentsCheck(false));
            dispatch(setVIN(event.target.value));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              searchHandler(dispatch);
            }
          }}
          //////////////
          style={{ userSelect: "text", cursor: "auto" }}
          readOnly={false}
        ></input>
        <label
          htmlFor="searchDetails"
          className={classes.searchLabel}
          data-cy="searchLabel"
          style={{ userSelect: "text", cursor: "auto" }}
        ></label>
        <div
          // enter here all conditions that disable the btn animation
          className={
            isLoadingDataVINCheck
              ? classes.actionBtn
              : classes.actionBtnHighlight
          }
        >
          <img
            src={search}
            alt=""
            onClick={() =>
              //////NO VDS CHeck:
              {
                searchHandler(dispatch);
              }
            }
          ></img>{" "}
        </div>
        {/*  */}
      </div>
      {error ? (
        <div className={classes.flexContainerRowError}>
          Please enter a valid VIN{" "}
        </div>
      ) : (
        <div
          className={classes.flexContainerRow}
          style={{ height: "1.5rem" }}
        ></div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      ></div>
      <div className={classes.flexContainerAboveTable}>
        <div className={classes.aboveTableSection}>
          <div
            className={classes.aboveTableSectionTitle}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            Vehicle ID:
          </div>
          <div
            className={classes.aboveTableSectionText}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {vehicleUuID.VehicleId === undefined &&
            !emptyFields &&
            isLoadingDataVehicleUuid ? (
              <LoadingSpinner />
            ) : vehicleUuID.VehicleId === undefined &&
              !emptyFields &&
              !isLoadingDataVehicleUuid ? (
              "No vehicle ID available"
            ) : vehicleUuID.VehicleId === undefined && emptyFields ? (
              "No data available"
            ) : (
              vehicleUuID.VehicleId
            )}
          </div>
        </div>
        <div className={classes.aboveTableSection} style={{ margin: "0 1rem" }}>
          <div
            className={classes.aboveTableSectionTitle}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            Service Group:
          </div>
          <div
            className={classes.aboveTableSectionText}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {isLoadingDataServiceGroup && !emptyFields ? (
              <LoadingSpinner />
            ) : serviceGroup.ServiceGroupCode === undefined && emptyFields ? (
              "No data available"
            ) : (
              serviceGroup.ServiceGroupCode
            )}
          </div>
        </div>{" "}
        <div className={classes.aboveTableSection}></div>{" "}
      </div>
      <div className={classes.flexContainerRow}></div>
      <div className={classes.flexContainerRowSwimlane}></div>
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRowTable}>
        <SopTable />
        <VehicleStatusTableLive />
      </div>
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRow}> </div> <WideTable />
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRow}> </div> <OdpUserList />
      <div className={classes.flexContainerRow}> </div>
      <div className={classes.flexContainerRow}> </div>
      <JsonProtocols />
    </div>
  );
}

export default BackendDataInfoPage;
