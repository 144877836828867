import React, { useEffect, useRef, useState } from "react";
import Papa from "papaparse";
import { useCSVDownloader } from "react-papaparse";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import classes from "./CcpGenerator.module.css";
// import HorizontalDevider from "../../components/Horizontal Divider/HorizontalDivider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  generateCCPFile,
  selectPhone1,
  selectPhone2,
  selectPhone3,
  selectPhone4,
  selectPhone5,
  selectPhone6,
  selectPhone7,
  selectPhone8,
  selectSelectedBackend,
  selectSelectedCountry1,
  selectSelectedDevice,
  selectSelectedEnvironment,
  setPhone1,
  setPhone2,
  setPhone3,
  setPhone4,
  setPhone5,
  setPhone6,
  setPhone7,
  setPhone8,
  setSelectedBackend,
  setSelectedCountry1,
  setSelectedDevice,
  setSelectedEnvironment,
} from "./ccpGeneratorSlice";
import Input, { getCountries } from "react-phone-number-input/input";

function ManualInput() {
  const dispatch = useDispatch();
  const selectedCountry1 = useSelector(selectSelectedCountry1);

  const [defaultCountry1, setDefaultCountry1] = useState(selectedCountry1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const fileInputRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedTab] = useState("upload");

  const environment = useSelector(selectSelectedBackend);
  const stage = useSelector(selectSelectedEnvironment);
  const device = useSelector(selectSelectedDevice);
  const eCall_ACN_Number_TSP = useSelector(selectPhone1);
  const eCall_MEC_Number_TSP = useSelector(selectPhone2);
  const eCall_ACN_Number_ECC = useSelector(selectPhone3);
  const eCall_MEC_Number_ECC = useSelector(selectPhone4);
  const eCall_MSD_SMS_Number = useSelector(selectPhone5);
  const OPR_Fallback_Number = useSelector(selectPhone6);
  const AiA_Fallback_Number = useSelector(selectPhone7);
  const ShortMessageTelematicsBackend = useSelector(selectPhone8);

  //////// CSV generator
  const { CSVDownloader } = useCSVDownloader();

  const headers = [
    "Environment",
    "Stage",
    "Country",
    "ECU",
    "eCall_ACN_Number_TSP",
    "eCall_MEC_Number_TSP",
    "eCall_ACN_Number_ECC",
    "eCall_MEC_Number_ECC",
    "eCall_MSD_SMS_Number",
    "OPR_Fallback_Number",
    "AiA_Fallback_Number",
    "ShortMessageTelematicsBackend",
  ];

  const rowData = {
    Environment: environment,
    Stage: stage,
    Country: selectedCountry1.toUpperCase(),
    ECU: device,
    eCall_ACN_Number_TSP: eCall_ACN_Number_TSP,
    eCall_MEC_Number_TSP: eCall_MEC_Number_TSP,
    eCall_ACN_Number_ECC: eCall_ACN_Number_ECC,
    eCall_MEC_Number_ECC: eCall_MEC_Number_ECC,
    eCall_MSD_SMS_Number: eCall_MSD_SMS_Number,
    OPR_Fallback_Number: OPR_Fallback_Number,
    AiA_Fallback_Number: AiA_Fallback_Number,
    ShortMessageTelematicsBackend: ShortMessageTelematicsBackend,
  };

  const csvData = `${headers.join(";")}\n${Object.values(rowData).join(";")}`;

  ///////////////////////

  const generateButtonAvailable =
    ((eCall_ACN_Number_TSP !== "" && eCall_ACN_Number_TSP.length >= 4) ||
      eCall_MEC_Number_TSP !== "" ||
      eCall_ACN_Number_ECC !== "" ||
      eCall_MEC_Number_ECC !== "" ||
      eCall_MSD_SMS_Number !== "" ||
      OPR_Fallback_Number !== "" ||
      AiA_Fallback_Number !== "" ||
      ShortMessageTelematicsBackend !== "") &&
    environment !== "" &&
    stage !== "" &&
    device !== ""
      ? classes.ccpGeneratorContentsActionSectionSelectButtonGenerate
      : classes.ccpGeneratorContentsActionSectionSelectButtonGenerateUnavailable;

  const downloadButtonAvailable =
    selectedFile !== null
      ? classes.ccpGeneratorContentsActionSectionSelectButtonDownload
      : classes.ccpGeneratorContentsActionSectionSelectButtonDownloadUnavailable;

  const downloadHandler = () => {
    if (selectedFile) {
      dispatch(generateCCPFile(selectedFile));
      setFileName("");
    } else {
      console.log("No file selected");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  // Backend selection
  useEffect(() => {
    setSelectedTab("upload");
    if (selectedCountry1 !== defaultCountry1) {
      setDefaultCountry1(selectedCountry1.toUpperCase());
    }
    console.log("env:" + environment);
  }, [defaultCountry1, selectedCountry1, environment]);

  return (
    <div className={classes.ccpGeneratorContentsInputFields}>
      {/* Phone related selection */}
      <div className={classes.ccpGeneratorContentsInputFieldsPhone}>
        <div className={classes.ccpGeneratorContentsInputFieldsPhoneInfo}>
          <div className={classes.ccpGeneratorContentsInputFieldsPhoneInfoText}>
            {" "}
            1. Enter relevant phone numbers *
          </div>
          <div
            className={classes.ccpGeneratorContentsInputFieldsPhoneInfoDetails}
          >
            {" "}
            * When entering your phone number, omit the starting "0" and begin
            directly after the country prefix. For example, for Romania, start
            with "7xx xxx xxx" after selecting "+40". For Germany, start with
            "xxxx xxx xxx" after selecting "+49".
          </div>
        </div>
        <div
          className={
            classes.ccpGeneratorContentsInputFieldsPhoneSelectSectionCol
          }
        >
          <div
            className={
              classes.ccpGeneratorContentsInputFieldsPhoneSelectSection
            }
          >
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                1. Country/ eCall_ACN_Number_TSP:
              </div>
              <PhoneInput
                className={classes.phoneInputComponent}
                defaultCountry={selectedCountry1}
                optional={false}
                value={eCall_ACN_Number_TSP}
                onChange={(phone, country) => {
                  dispatch(setPhone1(phone));
                  console.log(phone);
                  dispatch(setSelectedCountry1(country.country.iso2));
                }}
              />
            </div>
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                2. eCall_MEC_Number_TSP:
              </div>
              <Input
                className={classes.phoneInputComponentOnlyInput}
                international
                country={getCountries()
                  .filter(
                    (country) => country.toLowerCase() === selectedCountry1
                  )
                  .toString()}
                value={eCall_MEC_Number_TSP}
                withCountryCallingCode
                onChange={(phone, country) => {
                  console.log(phone);
                  dispatch(setPhone2(phone));
                }}
              />
            </div>
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                3. eCall_ACN_Number_ECC:
              </div>
              <Input
                className={classes.phoneInputComponentOnlyInput}
                international
                country={getCountries()
                  .filter(
                    (country) => country.toLowerCase() === selectedCountry1
                  )
                  .toString()}
                withCountryCallingCode
                value={eCall_ACN_Number_ECC}
                onChange={(phone) => {
                  dispatch(setPhone3(phone));
                }}
              />
            </div>
          </div>
          {/* ------ */}
          {/* ------Second row------ */}
          {/* ------ */}
          <div
            className={
              classes.ccpGeneratorContentsInputFieldsPhoneSelectSection
            }
          >
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                4. eCall_MEC_Number_ECC
              </div>
              <Input
                className={classes.phoneInputComponentOnlyInput}
                international
                country={getCountries()
                  .filter(
                    (country) => country.toLowerCase() === selectedCountry1
                  )
                  .toString()}
                value={eCall_MEC_Number_ECC}
                withCountryCallingCode
                onChange={(phone, country) => {
                  dispatch(setPhone4(phone));
                }}
              />
            </div>
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                5. eCall_MSD_SMS_Number
              </div>
              <input
                className={classes.phoneInputComponentOnlyInput}
                value={eCall_MSD_SMS_Number}
                onChange={(event) => {
                  dispatch(setPhone5(event.target.value));
                }}
              />
            </div>
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                6. OPR_Fallback_Number
              </div>
              <Input
                className={classes.phoneInputComponentOnlyInput}
                international
                country={getCountries()
                  .filter(
                    (country) => country.toLowerCase() === selectedCountry1
                  )
                  .toString()}
                value={OPR_Fallback_Number}
                withCountryCallingCode
                onChange={(phone, country) => {
                  dispatch(setPhone6(phone));
                }}
              />
            </div>
          </div>
          {/* ------ */}
          {/* ------ */}
          {/* -----Third row ------ */}
          {/* ------ */}
          {/* ------ */}
          <div
            className={
              classes.ccpGeneratorContentsInputFieldsPhoneSelectSection
            }
          >
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                7. AiA_Fallback_Number
              </div>
              <Input
                className={classes.phoneInputComponentOnlyInput}
                international
                country={getCountries()
                  .filter(
                    (country) => country.toLowerCase() === selectedCountry1
                  )
                  .toString()}
                value={AiA_Fallback_Number}
                withCountryCallingCode
                onChange={(phone, country) => {
                  dispatch(setPhone7(phone));
                }}
              />
            </div>
            <div className={classes.ccpGeneratorContentsInputFieldsPhoneSelect}>
              <div className={classes.phoneInputComponentInfo}>
                8. Short Message Telematics Backend
              </div>
              <input
                className={classes.phoneInputComponentOnlyInput}
                value={ShortMessageTelematicsBackend}
                onChange={(event) => {
                  dispatch(setPhone8(event.target.value));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* End of phone related selection */}
      {/*  */}
      {/*  */}
      {/* Backend related selection */}
      <div className={classes.ccpGeneratorContentsInputFieldsBackend}>
        <div className={classes.ccpGeneratorContentsInputFieldsBackendInfo}>
          2. Select Environment
        </div>
        <div className={classes.ccpGeneratorContentsInputFieldsBackendSelect}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="backend-selection"
              name="backend"
              defaultValue=""
              onChange={(event) => {
                dispatch(setSelectedBackend(event.target.value));
                console.log(event.target.value);
              }}
            >
              <FormControlLabel
                value="WEB"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                      },
                    }}
                  />
                }
                label="MBB1.5"
                labelPlacement="end"
              />
              <FormControlLabel
                value="ODP"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                      },
                    }}
                  />
                }
                label="ODP1.0"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {/* End of backend related selection */}
      {/*  */}
      {/*  */}
      {/* Environment related selection */}
      <div className={classes.ccpGeneratorContentsInputFieldsEnvironment}>
        <div className={classes.ccpGeneratorContentsInputFieldsEnvironmentInfo}>
          3. Select stage
        </div>
        <div
          className={classes.ccpGeneratorContentsInputFieldsEnvironmentSelect}
        >
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="position"
              row
              onChange={(event) => {
                dispatch(setSelectedEnvironment(event.target.value));
                console.log(event.target.value);
              }}
            >
              <FormControlLabel
                value="Demo"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        fontFamily: "var(--regular)",
                      },
                    }}
                  />
                }
                label="Demo"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Approval"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                        fontFamily: "var(--regular)",
                      },
                    }}
                  />
                }
                label="Approval"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Live"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                        fontFamily: "var(--regular)",
                      },
                    }}
                  />
                }
                label="Live"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {/* End of Envionment related selection */}
      {/*  */}
      {/*  */}
      {/* Device related selection */}
      <div className={classes.ccpGeneratorContentsInputFieldsDevice}>
        <div className={classes.ccpGeneratorContentsInputFieldsDeviceInfo}>
          4. Select ECU (Vehicle Device)
        </div>
        <div className={classes.ccpGeneratorContentsInputFieldsDeviceSelect}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="position"
              row
              onChange={(event) => {
                dispatch(setSelectedDevice(event.target.value));
              }}
            >
              <FormControlLabel
                value="CGW"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                      },
                    }}
                  />
                }
                label="CGW"
                labelPlacement="end"
              />
              <FormControlLabel
                value="GW31"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                      },
                    }}
                  />
                }
                label="GW31"
                labelPlacement="end"
              />
              <FormControlLabel
                value="GW30"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                      },
                    }}
                  />
                }
                label="GW30"
                labelPlacement="end"
              />
              <FormControlLabel
                value="OTAFC"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                      },
                    }}
                  />
                }
                label="OTAFC"
                labelPlacement="end"
              />
              <FormControlLabel
                value="HCP5"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                      },
                    }}
                  />
                }
                label="HCP5"
                labelPlacement="end"
              />
              <FormControlLabel
                value="conboxhigh"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        marginLeft: "2rem",
                      },
                    }}
                  />
                }
                label="conboxhigh"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {/* End of device related selection */}
      {/*  */}
      {/* File generation section */}
      <div className={classes.ccpGeneratorContentsActionSection}>
        <div className={classes.ccpGeneratorContentsActionSectionInfo}>
          5. Generate file
        </div>
        <div className={classes.ccpGeneratorContentsActionSectionSelect}>
          {/* Download functionality */}
          <div>
            {((eCall_ACN_Number_TSP !== "" &&
              eCall_ACN_Number_TSP.length >= 4) ||
              eCall_MEC_Number_TSP !== "" ||
              eCall_ACN_Number_ECC !== "" ||
              eCall_MEC_Number_ECC !== "" ||
              eCall_MSD_SMS_Number !== "" ||
              OPR_Fallback_Number !== "" ||
              AiA_Fallback_Number !== "" ||
              ShortMessageTelematicsBackend !== "") &&
            environment !== "" &&
            stage !== "" &&
            device !== "" ? (
              <CSVDownloader
                className={generateButtonAvailable}
                bom={true}
                filename={"manually_generated_csv_file"}
                config={{
                  delimiter: ";",
                }}
                data={csvData}
              >
                Generate & Download .csv
              </CSVDownloader>
            ) : (
              <div
                className={
                  classes.ccpGeneratorDownloadContentsActionSectionSelectButtonDisabledCSVDownloader
                }
              >
                Generate & Download .csv
              </div>
            )}
          </div>
          {/* Upload generated CSV */}
          <div>
            <input
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <div
              className={
                classes.ccpGeneratorContentsActionSectionSelectButtonUpload
              }
              onClick={() => fileInputRef.current.click()}
            >
              Upload generated .csv
            </div>
          </div>
          {/* Send CSV functionality */}
          <div>
            <div className={downloadButtonAvailable} onClick={downloadHandler}>
              Download .xml CCP-file
            </div>
          </div>
        </div>
      </div>
      {fileName !== "" ? (
        <div className={classes.fileNameBoxManual}>
          File selected: {fileName}
        </div>
      ) : (
        <div></div>
      )}{" "}
      {/*  */}
    </div>
  );
}

export default ManualInput;
